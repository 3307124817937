<script>

import {financial} from '@/api/misc'

export default {


    props: ['deal'],

    data() {
       return {}
    },

    computed: {
        is_subject_removed() {
            return this.deal?(this.deal.is_subject_deal == 1 ? (this.deal.subject_remove_flag == 1?true : false) : true) : false;
        }
    },

    methods: {
        f(n, d=2) {
            return financial(n, d)
        },
    },
    created() {


    },

    mounted() {
       
    }
}
</script>

<template>
    <div class="row">
        <div class="col-lg-3">
            <div class="mt-3">
                <ul class="text-muted">

                    <li class="py-1 font-size-15 ">Status: {{ deal.order_state }}</li>
                    <li class="py-1 font-size-15 ">Price: ${{ f(deal.selling_price).toLocaleString() }}</li>
                    <li class="py-1 font-size-15 ">Listing Commission: ${{ f(deal.listing_commission + deal.listing_commission_gst).toLocaleString() }}</li>
                    <li class="py-1 font-size-15 ">Listing Side: <br />
                        <span v-if="deal.deal_type == 'S'">
                            <span v-for="(a, idx) in deal.listing_brokers" class="font-size-14 " :key="'listing_agent_' + idx">
                                {{ `${a.name}(Agent : ${a.first_name} ${a.last_name})` }} <br />
                            </span>
                        </span>
                        <span v-else>
                            <span v-for="(a, idx) in deal.agents.filter(e=>e.agent_type == '1')" class="font-size-14 " :key="'listing_agent_' + idx">
                                {{ `${a.brokerage} (Agent : ${a.first_name} ${a.last_name})`  }}  <br />
                            </span>
                        </span>
                    </li>
                    <li class="py-1 font-size-15 ">Selling Commission: ${{ f(deal.selling_commission + deal.selling_commission_gst).toLocaleString() }}</li>
                    <li class="py-1 font-size-15 ">Selling Side: <br />
                        <span v-if="deal.deal_type == 'L'">
                            <span v-for="(a, idx) in deal.selling_brokers" class="font-size-14 " :key="'selling_agent_' + idx">
                                {{ `${a.name}(Agent : ${a.first_name} ${a.last_name})` }} <br />
                            </span>
                        </span>
                        <span v-else>
                            <span v-for="(a, idx) in deal.agents.filter(e=>e.agent_type == '2')" class="font-size-14 " :key="'selling_agent_' + idx">
                                {{ `${a.first_name} ${a.last_name}` }} <br />
                            </span>
                        </span>
                    </li>

                    <li class="py-1 font-size-15 ">Conveyancer: {{ deal.process_staff_name }}</li>

                </ul>
            </div>
        </div>
        <!-- end col -->

        <div class="col-lg-6">
            <div class="mt-3">
                <ul class="text-muted">
                    <li v-for="(buyer, idx) in deal.buyers" class="py-1 font-size-15 " :key="'deal_buyers_' + idx">Buyer: <br />
                        {{ buyer.first_name +' ' + buyer.last_name }} {{ `${buyer.phone != null? '/'+buyer.phone : ''}` }} {{ `${buyer.email != null? '/'+buyer.email : ''}` }} <br />
                    </li>
                    <li v-for="(seller, idx) in deal.sellers" class="py-1 font-size-15 " :key="'deal_sellers_' + idx">Seller: <br />
                        {{ seller.first_name +' ' + seller.last_name  }} {{ `${seller.phone != null? '/'+seller.phone : ''}` }} {{ `${seller.email != null? '/'+seller.email : ''}` }} <br />
                    </li>


                    <li class="py-1 font-size-15 ">Lawyers: {{ !(deal.lawyers && deal.lawyers.length > 0) ? 'N/A' :'' }}</li>
                    <span v-for="(lawyer, idx) in deal.lawyers"  class="py-1 font-size-14 " :key="'deal_lawyers_' + idx" >
                        {{ `${lawyer.type} : ${lawyer.name} ${lawyer.email} ${lawyer.phone}` }} <br />
                    </span>

                    
                    <li class="py-1 font-size-15 ">Address: </li>
                    <span v-for="(p, idx) in deal.properties" class="font-size-14 " :key="'deal_p_' + idx">
                        {{ `${p.unit ? p.unit : ''} ${p.address}` }}
                    </span>
                </ul>
            </div>
        </div>

        <div class="col-lg-3">
            <div class="row">

                <div class="col-lg-5 col-sm-5">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                            <i class="uil uil-calendar-alt text-primary font-size-22"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="font-size-13 mb-1">Subject Remove</h5>
                            <p class="text-muted mb-0">{{ deal.is_subject_deal?deal.subject_remove_date : 'N/A' }}</p>
                        </div>
                    </div>
                </div>
                <!-- end col -->
                <div class="col-lg-5 col-sm-5">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                            <i class="uil uil-check-circle  font-size-22"
                                :class="deal.is_subject_deal  && deal.subject_remove_flag == 1 ? 'text-primary' : 'd-none'"></i>
                        </div>
                        <div class="flex-grow-1" v-if="deal.is_subject_deal && deal.subject_remove_flag == 1">
                            <h5 class="font-size-15 mb-1">Subject Removed</h5>
                            <p class="text-muted mb-0"></p>
                        </div>
                    </div>
                </div>
                <!-- end col -->


            </div>
            <!-- end row -->
            <div class="row">
                <div class="col-lg-5 col-sm-5">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                            <i class="uil uil-calendar-alt text-primary font-size-22"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="font-size-15 mb-1">Acceptance</h5>
                            <p class="text-muted mb-0">{{ deal.acceptance_date }}</p>
                        </div>
                    </div>
                </div>
                <!-- end col -->

                <div class="col-lg-5 col-sm-5" v-if="deal.closing_date">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3" >
                            <i class="uil uil-check-circle text-primary font-size-22"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="font-size-15 mb-1">Finalized</h5>
                            <p class="text-muted mb-0">{{ deal.closing_date }}</p>
                        </div>
                    </div>
                </div>
                <!-- end col -->

            </div>
            <!-- end row -->

            <div class="row">

                <div class="col-lg-5 col-sm-5">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                            <i class="uil uil-calendar-alt text-primary font-size-22"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="font-size-15 mb-1">Completion</h5>
                            <p class="text-muted mb-0">{{ deal.completion_date }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-sm-5">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                            <i class="uil uil-check-circle text-primary font-size-22"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="font-size-15 mb-1">Balance</h5>
                            <p class="text-muted mb-0">${{ f(deal.balance).toLocaleString() }}</p>
                        </div>
                    </div>
                </div>
                <!-- end col -->



            </div>
            <!-- end row -->


        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
</template>