<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";




import FolderListTab from './components/tab_folder.vue'
//import CashFlowTab from './components/tab_cash_flow.vue'
import StateLogTab from './components/tab_state_log.vue'


import ConveyanceTab from './components/tab_conveyance'

import AccountingTab from './components/tab_accounting.vue'

import CloseDealDialog from './components/close_deal_dialog.vue'
import RejectDialog from './components/reject_dialog.vue'
import SubjectRemoveDialog from './components/subject_remove_dialog.vue'
import ReportDialog from './components/report_generate_dialog.vue'
import SendReportDialog from '@/components/mail-editor.vue'
import AgentCommissionDialog from './components/agent_commission_edit_dialog.vue'
import DepositChequeDialog from './components/deposit_cheque_dialog.vue'
import ExcessDepositDialog from './components/excess_deposit_dialog.vue'
import TermTransferDialog from "./components/term_transfer_dialog.vue";

import InfoResidentialCard  from './components/detail_info_residential.vue'
import InfoPlacementCard from './components/detail_info_placement_fee.vue'
import InfoReferralsCard from './components/detail_info_referral_fee.vue'

import appConfig from "@/app.config";
import { getDealApi,} from '@/api/deal'
import {getDealProcessApi} from '@/api/deal/process'
import {getDealBasicApi} from '@/api/deal/basic'

import {financial} from '@/api/misc'

/**
 * Project-overview component
 */
export default {
  page: {
    title: "Deal Overview",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Deal Overview",
      items: [
        {
          text: "Deal",
        },
        {
          text: "Overview",
          active: true,
        },
      ],

      deal: {
        cash_flows: [],
        ledger_preview: {
          total_commission: 0,
          total_received: 0,
          balance_in_trust : 0,
        },
        agents: []

      },

      basic: {
        sold_price: 0,
        acceptance_date: '',
        completion_date: '',
        possession_date: '',
      },

      native_agents: [],
      is_subject_removed: true,
      is_deposit: false

    };
  },
  components: {
    Layout,
    PageHeader,

    InfoResidentialCard,
    InfoPlacementCard,
    InfoReferralsCard,

    CloseDealDialog,
    RejectDialog,
    SubjectRemoveDialog,
    ReportDialog,
    SendReportDialog,
    AgentCommissionDialog,
    DepositChequeDialog,
    ExcessDepositDialog,
    TermTransferDialog,


    FolderListTab,


    ConveyanceTab,
   
    StateLogTab,
   // CashFlowTab,
    AccountingTab
  },

  computed: {
    process_excess_btn_disabled() {
      if (this.deal.order_state == 'CLOSED' || this.deal.order_state == 'COLLAPSED') {
        return true;
      }
      return false
    },

    subject_remove_btn_disabled() {
      if (this.deal.order_state == 'CLOSED' || this.deal.order_state == 'COLLAPSED') {
        return true;
      }
      return this.is_subject_removed
    }
  },

  methods: {
    f(n, d=2) {
      return financial(n, d)
    },





    deal_report() {

    },

    deal_process() {


      let data = { deal_id: this.deal.order_deal_id }
      getDealApi().deal_process(data).then((res) => {
        if (res.errCode == 0) {
          this.deal.order_state = 'PROCESSING'
          this.$alertify.message("Deal Processed ");
          this.$bvModal.hide('modal-basic-info-edit-dialog')
        } else {
          this.$alertify.message("Failed to Update " + res.msg);
        }
      })
    },

    

    deal_reject(reasons) {
      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Reject Deal?",
        () => {
          let data = { deal_id: this.deal.order_deal_id, reasons : reasons }
          getDealApi().deal_reject(data).then((res) => {
            if (res.errCode == 0) {
              this.deal.order_state = 'REJECTED'
              this.$alertify.message("Deal Rejected ");
              this.$bvModal.hide('modal-deal-reject-dialog')
            } else {
              this.$alertify.message("Failed to Update " + res.msg);
            }
          })
        },
        () => {

        }
      );

    },




    onExcessedDeposit() {
      this.$bvModal.hide('modal-basic-info-edit-dialog')
    },

    deal_close(close_file) {
      
      let data = { 
        order_deal_no   : this.deal.order_deal_id,
        close_number    : close_file.close_number,
        close_file_url  : close_file.save_route,
        close_file_name : close_file.file_name,
        auto_pay_agent  : close_file.auto_pay? 1 : 0
       }

      getDealProcessApi().close_deal(data).then((res) => {
        if (res.errCode == 0) {
          this.deal.order_state = 'CLOSED'
          this.$alertify.message("Deal Closed ");
          this.$bvModal.hide('modal-deal-close-dialog')
          this.$router.go()
        } else {
          this.$alertify.error("Failed to Close Deal :" + res.msg);
        }
      })
    },

    deal_collapse() {

      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Collapse Deal?",
        () => {
          let data = { order_deal_no: this.deal.order_deal_id }
          getDealProcessApi().collapse(data).then((res) => {
            if (res.errCode == 0) {
              this.deal.order_state = 'COLLAPSED'
              this.$alertify.message("Deal Collapsed ");
              this.$bvModal.hide('modal-basic-info-edit-dialog')
              this.$router.go()
            } else {
              this.$alertify.message("Failed to Collapse " + res.msg);
            }
          })
        },
        () => {

        }
      );

    },


    onDepositCheque() {
      this.$bvModal.hide('modal-deposit-cash-dialog')
      this.$router.go()
    },

    onExcessDeposit() {
      this.$bvModal.hide('modal-excess-deposit-dialog')
      this.$router.go()
    },


    send_sales_report(content) {
      let data  = {
        subject : content.subject,
        content : content.content
      }
      getDealApi().send_sales_report(data).then((res) => {
        if (res.errCode == 0) {
          this.$alertify.message("Sales Report was sent");
          this.$bvModal.hide('modal-send-report-dialog')
        } else {
          this.$alertify.error("Failed to Send Sales Report " + res.msg);
        }
      })
    },

  
    onEditAgentcommission() {
      this.$router.go()
    },

    onTransfered() {
      this.$alertify.message("set up Transfer");
      this.$bvModal.hide('modal-interest-transfer-dialog')
    },

   
    check_trust(deal) {
      return (deal.ledger_preview.balance_in_trust == deal.ledger_preview.total_commission)
    },

    deal_unwind() {

      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Unwind Deal?",
        () => {
          let data = { order_deal_no: this.deal.order_deal_id}
          getDealBasicApi().deal_unwind(data).then((res) => {
            if (res.errCode == 0) {
             
              this.$alertify.message("Deal Unwinded ");
              this.$router.push({name : 'deal-pending-list'})
            } else {
              this.$alertify.message("Failed to Unwind " + res.msg);
            }
          })
        },
        () => {

        }
      );
      
    }



  },



  created() {


    getDealApi().detail({ deal_id: this.$route.query.deal_id }).then((res) => {
      if (res.errCode == 0) {
        this.deal = res.data
        this.is_subject_removed = this.deal.is_subject_deal == 1 ? (this.deal.subject_remove_flag == 1?true : false) : true;

        //check deposit status
        let flag = false
        this.deal.deposits.map((s) => {
          if (s.state == 'CONFIRMED') {
            flag = true
          }
        })

        if (!this.deal.folders || this.deal.folders.length <= 0) {
          this.deal.folder_group.map(fg => {
            this.deal.folders.push(...fg.folders)
          })
        }

        this.is_deposit = flag
      }

    })

  },
  mounted() {

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-0">
          <div class="card-body pb-5">
            <div class="row">
              <div class="col">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div class="avatar">
                      <div class="avatar-title bg-soft-primary text-primary font-size-18 rounded">
                        D
                      </div>
                    </div>
                  </div>
                  <div class=" ms-4">
                    <div class="text-muted">
                      <h5 class="font-size-16 mb-2">{{ deal.order_deal_id }}</h5>
                      <!-- h5 class="font-size-16 mb-2">{{deal.deal_type_name}} </h5 -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->

              <div class="col-auto">
                <div class="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3">

                 
                  <!-- <b-button  variant="info" v-b-modal.modal-send-report-dialog>Send Report</b-button> -->

                  <b-button  variant="info" v-b-modal.modal-report-generate-dialog>Report</b-button>

                  <b-button  variant="info" @click="deal_process"
                    :disabled="!(deal.order_state == 'SUBMITTED')">Process</b-button>
                  <!-- button type="button" class="btn btn-danger" @click="$bvModal.show('modal-deal-reject-dialog')"
                  :disabled="!(deal.order_state == 'PROCESSING')" >Reject</button -->
                  <b-button variant="purple" type="button"  :disabled="subject_remove_btn_disabled" class="btn btn-light" v-b-modal.modal-subject-remove-dialog >
                      Subject Remove
                  </b-button>
                  <b-button  b-button variant="warning" v-b-modal.modal-excess-deposit-dialog
                    :disabled="process_excess_btn_disabled">Excess Deposit</b-button>
                  <b-button variant="success" :disabled="(deal.order_state == 'CLOSED' || deal.order_state == 'COLLAPSED')" @click="$bvModal.show('modal-deal-close-dialog')" >Close</b-button>
                  <b-button variant="dark" @click="deal_collapse"
                    :disabled="(deal.order_state == 'CLOSED' || deal.order_state == 'COLLAPSED')">Collapse</b-button>
                  <b-button variant="dark"  v-b-modal.modal-deposit-cash-dialog
                   :disabled="(deal.order_state == 'CLOSED' || deal.order_state == 'COLLAPSED')"
                    >Deposit</b-button>


                  <b-dropdown variant="link" toggle-class="shadow-none text-dark" right :disabled="(deal.order_state == 'CLOSED' || deal.order_state == 'COLLAPSED')">
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <li><b-dropdown-item @click="$router.push({name : 'deal-edit', query:{deal_id : deal.order_deal_id}})"> Edit </b-dropdown-item></li>
                    <li><b-dropdown-item v-b-modal.modal-agent-commission-edit-dialog> Edit Agent Commission</b-dropdown-item></li>
                    <li><b-dropdown-item v-b-modal.modal-interest-transfer-dialog> Term Transfer</b-dropdown-item></li>
                    <li><b-dropdown-item @click="deal_unwind">Unwind</b-dropdown-item></li>
                    
                  </b-dropdown>

                  
                  
                  <b-modal centerd id="modal-interest-transfer-dialog" title="Interests" size="lg" hide-footer>
                    <TermTransferDialog :deal="deal" @cancel="$bvModal.hide('modal-interest-transfer-dialog')" @confirm="onTransfered"/>
                  </b-modal>
                  
                  <b-modal centerd id="modal-report-generate-dialog" title="Report" size="lg" hide-footer>
                    <ReportDialog :deal="deal" @cancel="$bvModal.hide('modal-report-generate-dialog')" />
                  </b-modal>

                
                  <b-modal centerd id="modal-deal-close-dialog" title="Close Deal" size="lg" hide-footer>
                    <CloseDealDialog :commsissions="deal.ledger_preview.agent_commissions" @cancel="$bvModal.hide('modal-deal-close-dialog')"
                      @confirm="deal_close" />
                  </b-modal>

                  <b-modal centerd id="modal-deal-reject-dialog" title="Reject Deal Dialog" size="xl" hide-footer>
                    <RejectDialog @cancel="$bvModal.hide('modal-deal-reject-dialog')"
                      @confirm="deal_reject" />
                  </b-modal>

                  <b-modal centered id="modal-subject-remove-dialog" title="Subject Remove" size="lg" hide-footer>
                      <SubjectRemoveDialog :deal="deal"  @cancel="$bvModal.hide('modal-subject-remove-dialog')" @confirm="$bvModal.hide('modal-subject-remove-dialog')"/>
                  </b-modal>

                  <b-modal centerd id="modal-send-report-dialog" title="Send Report" size="lg" hide-footer>
                    <SendReportDialog :deal="deal" @close="$bvModal.hide('modal-send-report-dialog')" @send="send_sales_report"/>
                  </b-modal>

                  <b-modal centerd id="modal-agent-commission-edit-dialog" title="Agent Commission" size="lg" hide-footer>
                    <AgentCommissionDialog :deal="deal" @cancel="$bvModal.hide('modal-agent-commission-edit-dialog')" @confirm="onEditAgentcommission"/>
                  </b-modal>
                  <b-modal centerd id="modal-deposit-cash-dialog" title="Deposit a  Cheque" size="lg" hide-footer>
                    <DepositChequeDialog :deal="deal" @cancel="$bvModal.hide('modal-deposit-cash-dialog')" @confirm="onDepositCheque" />
                  </b-modal>
                  <b-modal centerd id="modal-excess-deposit-dialog" title="Excess Deposit" size="lg" hide-footer>
                    <ExcessDepositDialog :deal="deal" @cancel="$bvModal.hide('modal-excess-deposit-dialog')" @confirm="onExcessDeposit" />
                  </b-modal>
                  

                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <InfoResidentialCard :deal="deal" v-if="deal.deal_type !='P' && deal.deal_type !='H'"/>
            <InfoPlacementCard :deal="deal" v-if="deal.deal_type =='P'" />
            <InfoReferralsCard :deal="deal" v-if="deal.deal_type =='H'" />
            
          </div>
          <!-- end card body -->

        </div>
        <!-- en card -->
        <b-tabs class="mt-n5 pt-2" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
          nav-wrapper-class="nav-tabs-custom">
         
         

        
          <!---  Convyance Tab-->
          <b-tab title="Commission">
            <ConveyanceTab :deal="deal" :native_agents="native_agents" />
          </b-tab>
           <!---  Accounting Tab-->
          <b-tab title="Accounting">
            <AccountingTab :deal="deal"  />
          </b-tab>


           <!---  Files Tab-->
           <b-tab title="Files">
            <FolderListTab  :title="title" :deal="deal" />
          </b-tab>

          <b-tab title="State Log">
            <StateLogTab :deal="deal" />
          </b-tab>
        </b-tabs>
        <!-- Nav tabs -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
