<script>


/**
 * Deposit Edit component
 */




import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Choices from "choices.js";
import CurrencyInput from '@/components/currency-input'

import { required } from "vuelidate/lib/validators";

import { getDealProcessApi } from '@/api/deal/process'
import { financial } from '@/api/misc'
import { getCashApi} from '@/api/cash'

export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],

    components: {
        vueDropzone: vue2Dropzone,
        CurrencyInput
    },

    validations() {
        if (this.transfer_type  == 'CHEQUE') {
            return {
                transaction_number: { required },
                amount: { required },
            }
        } else   {
            return {
                transaction_number: {  },
                amount: { required },
            }
        }
       
    },



    data() {
        return {
            submitted: false,
            file_validate: true,
            file: {},
            transaction_number: '',
            payor: '',
            payor_address: '',
            transfer_type : 'CHEQUE',
            amount: 0,
            bank_account_id : '',
            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                maxFilesize: 10,
            },

        }
    },

    methods: {

        f(n, d = 2) {
            return financial(n, d)
        },

        formSubmit() {
            this.$v.$touch();
            if (this.file_validate == true && this.transfer_type  == 'CHEQUE') {
                this.$alertify.error("Please Upload scaned cheque ");
                return;
            }

            if (this.$v.$error == true) {
                return;
            }

            this.submit()

        },

        fileUploaded(ev, resp) {
            console.log("uploaded", ev.upload, ev, resp)
            if (resp[0].errCode == 200) {
                this.file_validate = false
                this.file = {
                    save_route: resp[0].response.url,
                    save_route_key: resp[0].response.key,
                    file_name: resp[0].response.filename,
                    size: resp[0].response.size,
                    type: resp[0].response.type
                }
            } else {
                this.file_validate = true
            }
        },




        submit() {
            let data = {
                cheque_file: this.file,
                cheque_number: this.transaction_number,
                order_deal_no: this.deal.order_deal_id,
                payor: this.payor,
                payor_address: this.payor_address,
                amount: this.amount,
                files : [this.file],
                bank_account_id : this.bank_account_id
            }

            getDealProcessApi().post_cheque_deposit(data).then(res => {
                if (res.errCode == 0) {
                    this.$emit('confirm', data)
                } else {
                    this.$alertify.message("Failed to Update ");
                }
            })

        },

        onDepositChange(evt) {
            this.bank_account_list.map(e => e.selected = false)
            if (evt.detail.value == 'Commission') {
               
                let bank_acocunt_obj = this.bank_account_list.find(e => e.bank_account.account_type == 'bankAccount_type2')
                this.bank_account_id = bank_acocunt_obj.bank_account.id
                bank_acocunt_obj.selected = true
            } else {
                let bank_acocunt_obj = this.bank_account_list.find(e => e.bank_account.account_type == 'bankAccount_type1')
                this.bank_account_id = bank_acocunt_obj.bank_account.id
                bank_acocunt_obj.selected = true
            }
            this.bank_account_choice.clearChoices()
            this.bank_account_choice.setChoices(this.bank_account_list)
           
        },

        onTypeChange() {

        }

    },

    created() {

    },

    mounted() {

        this.bank_account_choice = new Choices('#choices-bank-account')
        getCashApi().bank_account_list().then((res) => {
            if (res.errCode == 0) {
                this.bank_account_list = []
                res.data.accounts.map((b) => {
                  
                    if (b.account_type == 'bankAccount_type1' || b.account_type == 'bankAccount_type2') {
                        this.bank_account_list.push({
                            label : b.name,
                            value : b.id,
                            bank_account : b,
                            selected : b.account_type == 'bankAccount_type1'? true : false
                        })
                        if (b.account_type == 'bankAccount_type1') {
                            this.bank_account_id = b.id
                        }
                    }
                })
                this.bank_account_choice.setChoices(this.bank_account_list)
              
            }
        }),

        new Choices('#transfer_type')
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Bank Account</label>
                            <select class="form-control" v-model="bank_account_id" name="choices-bank-account" id="choices-bank-account" >
                            </select>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Deposit Type</label>
                            <select class="form-control" data-trigger v-model="transfer_type" name="type" id="transfer_type" @change="onTypeChange">
                                <option value="TRANSFER">TRANSFER</option>
                                <option value="CHEQUE" :selected="true" >CHEQUE</option>
                                <option value="WIRED">WIRED</option>
                            </select>
                        </div>

                    </div>
                   

                </div>
                <!-- end row-->


                <div class ="row" v-if="transfer_type == 'TRANSFER'">
                    <div class="row">

                        <div class="col-md-4">
                            <div class="mb-3">
                                <label  class="form-label">Amount</label>
                                <CurrencyInput v-model.lazy="amount" :class="{ 'is-invalid': $v.amount.$error }" />
                                <div v-if="$v.amount.$error" class="invalid-feedback">
                                    <span v-if="!$v.amount.required">This value is required.</span>
                                </div>
                            </div>

                        </div>

                        <div class="col-md-4">
                            <div class="mb-3">
                                <label  class="form-label">Transfer Number</label>
                                <input v-model="transaction_number" class="form-control"  type="text" :class="{
                                    'is-invalid': $v.transaction_number.$error,
                                }" />
                                <div v-if="$v.transaction_number.$error" class="invalid-feedback">
                                    <span v-if="!$v.transaction_number.required">This value is required.</span>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>

                <div class ="row" v-if="transfer_type == 'CHEQUE'">
                    <div class="row">

                        <div class="col-md-4">
                            <div class="mb-3">
                                <label  class="form-label">Amount</label>
                                <CurrencyInput v-model.lazy="amount" :class="{ 'is-invalid': $v.amount.$error }" />
                                <div v-if="$v.amount.$error" class="invalid-feedback">
                                    <span v-if="!$v.amount.required">This value is required.</span>
                                </div>
                            </div>

                        </div>


                        <div class="col-md-4">
                            <div class="mb-3">
                                <label  class="form-label">Cheque Number</label>
                                <input v-model="transaction_number" class="form-control"  type="text" :class="{
                                    'is-invalid': $v.transaction_number.$error,
                                }" />
                                <div v-if="$v.transaction_number.$error" class="invalid-feedback">
                                    <span v-if="!$v.transaction_number.required">This value is required.</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- end row-->

                    <div class="row">

                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="formFileSm" class="form-label">Payor</label>
                                <input v-model="payor" class="form-control"  type="text"  />
    
                            </div>

                        </div>

                        <div class="col-md-8">
                            <div class="mb-3">
                                <label for="formFileSm" class="form-label">Payor Address</label>
                                <input v-model="payor_address" class="form-control" type="text" />
                            </div>

                        </div>
                    </div>
                    <!-- end row-->

                    <div class="row">
                        <div class="col-xl-12">
                            <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions"
                                @vdropzone-success="fileUploaded">
                                <div class="dropzone-custom-content">
                                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                    <h4>Drop scaned cheque and receipt to here.</h4>
                                </div>
                            </vue-dropzone>
                        </div>

                    </div>
                    <!-- end row-->
                </div>

                <div class ="row" v-if="transfer_type == 'WIRED'">
                    <div class="row">

                        <div class="col-md-4">
                            <div class="mb-3">
                                <label  class="form-label">Amount</label>
                                <CurrencyInput v-model.lazy="amount" :class="{ 'is-invalid': $v.amount.$error }" />
                                <div v-if="$v.amount.$error" class="invalid-feedback">
                                    <span v-if="!$v.amount.required">This value is required.</span>
                                </div>
                            </div>

                        </div>

                        <div class="col-md-4">
                            <div class="mb-3">
                                <label  class="form-label">WIRED Number</label>
                                <input v-model="transaction_number" class="form-control"  type="text" :class="{
                                    'is-invalid': $v.transaction_number.$error,
                                }" />
                                <div v-if="$v.transaction_number.$error" class="invalid-feedback">
                                    <span v-if="!$v.transaction_number.required">This value is required.</span>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
                <!-- End WIRED-->


                <div class="row mt-3">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Submit</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>